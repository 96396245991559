body {
  background: rgb(106,106,106);
  background: radial-gradient(circle, rgba(106,106,106,1) 0%, rgba(0,0,0,1) 70%);
  background-repeat: repeat;
  background-size: cover;
  margin: 0;
  font-family: 'Julius Sans One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #FFFFFF;
}

code {
  font-family: 'Julius Sans One', sans-serif;
}
.MuiGrid-root{
  display: none!important;
}